<template>
  <div class="ticket" v-if="$route.path==='/mallModule/shop/online'">
    <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent" />
    <div class="content">
      <div class="header">
        <div class="operation">
          <el-button size="medium" type="primary" @click="handleAddClick">新建</el-button>
        </div>
      </div>
      <d-lable class="contentTable" :tableData='tableData' :columnData='columnData'>
        <template v-slot:approvalStatus="{ row }">
          <span v-if="row.approvalStatus == 1" class="blue">待审核</span>
          <span v-if="row.approvalStatus == 2" style="color: #F56C6C;">驳回</span>
          <span v-if="row.approvalStatus == 3" style="color: #04F21C">通过</span>
        </template>
        <template v-slot:shopStatus="{ row }">
          <span v-if="userInfo.type == 3"> {{ row.shopStatus == 1 ? '开启' : '暂停' }} </span>
          <el-switch v-model="row.shopSwitch" @change="handleChangeSwitch(row, $event)" :disabled="row.approvalStatus !== 3" v-else>
          </el-switch>
        </template>
        <template v-slot:shopLogo="{ row }">
          <img :src="row.shopLogo" alt="" class="img" v-viewer="{ toolbar: false }">
        </template>
        <template v-slot:action="{ row }">
          <span class="mgr10 blue" v-if="row.approvalStatus == 2" @click="handleEditClick(row)">修改</span>
          <span class="mgr10 blue"  v-if="row.approvalStatus == 3" @click="handleEditClick(row)">重新认证</span>
          <span class="mgr10 blue" @click="handleDetailClick(row)">详情</span>
          <span class="mgr10 blue" style="color: red;" v-if="row.shopStatus == 2" @click="handleDelete(row)">删除</span>
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager" @change="getItemList" />
  </div>
  <router-view v-else/>
</template>

<script>
import DSearch from "@/components/d-search";
import DLable from "@/components/d-table";
import DPaging from "@/components/d-paging";

const approvalStatusOptions = [
  {
    value: 1,
    label: '待审核'
  },
  {
    value: 2,
    label: '驳回'
  },
  {
    value: 3,
    label: '通过'
  },
]

const shopStatusOptions = [
  {
    value: 1,
    label: '开启'
  },
  {
    value: 2,
    label: '暂停'
  },
]
export default {
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    DSearch,
    DLable,
    DPaging,
  },
  data () {
    return {
      userInfo: null,
      childList: [],
      searchData: {
        searchItem: [
          {
            label: "企业名称",
            value: "companyName",
            type: "commonInput",
          },
          {
            label: '选择店铺',
            value: 'shopIds',
            type: 'linkageSelect',
            placeholder: '',
            options: [],
            filterable: true,
            optionProps: {
              value: 'id',
              label: 'name',
              children: 'childList'
            }
          },

          {
            label: "审核状态",
            value: "approvalStatus",
            type: "commonSelect",
            placeholder: "",
            options: approvalStatusOptions
          },
          {
            label: "店铺状态",
            value: "shopStatus",
            type: "commonSelect",
            placeholder: "",
            options: shopStatusOptions
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
        },
        params: {
          shopId: '',
          companyName: '',
          approvalStatus: '',
          shopStatus: '',
        },
      },
      columnData: [
        { prop: 'companyName', label: '企业名称' },
        { prop: 'shopNo', label: '企业ID' },
        { prop: 'name', label: '店铺名称' },
        { prop: 'shopLogo', label: '店铺LOGO', slotProp: true, width: 120 },
        { prop: 'contactInfo', label: '联系电话' },
        { prop: 'updateTime', label: '更新时间' },
        { prop: 'approvalStatus', label: '审批状态', slotProp: true },
        { prop: 'shopStatus', label: '店铺状态', slotProp: true },
        { prop: 'action', label: '操作', slotProp: true },
      ],
      tableData: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
    };
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/mallModule/shop/online') {
        this.searchEvent()
      }
    }
  },
  created () {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.searchEvent()
    this.getShopList()
  },
  methods: {
    handleDelete (row) {
      this.$confirm('确认删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const obj = {
            id: row.id
          }
          this.$api.mallApi.onlinePauseRemove(obj).then(({ code, data, msg }) => {
            if (code === 200) {
              this.$message.success(msg)
              this.searchEvent()
            } else {
              this.$message.error(msg)
            }
          })
        })
        .catch(() => {

        })
    },
    handleChangeSwitch(row, e) {
      let params = {
        id: row.id
      }
      let api = e ? 'onlineOpenBatch' : 'onlinePauseBatch'
      this.$api.mallApi[api](params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        this.getItemList()
      })
    },
    handleAddClick () {
      this.$router.push({ path:'/mallModule/shop/online/add' })
    },
    handleEditClick (row) {
      this.$router.push({ path:'/mallModule/shop/online/add', query:{id: row.id} })
    },
    handleDetailClick (row) {
      this.$router.push({ path:'/mallModule/shop/online/detail', query:{id: row.id} })
    },
    searchEvent () {
      this.pager.page = 1
      this.pager.rows = 10
      this.getItemList()
    },

    resetEvent () {
      this.getItemList()
    },

    setParams () {
      const { shopIds, companyName, shopStatus, approvalStatus } = this.searchData.params
      return {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        shopId: shopIds && shopIds.length > 0 ? shopIds[shopIds.length - 1] : null,
        companyName,
        shopStatus,
        approvalStatus
      }
    },
    getItemList () {
      const data = this.setParams()
      this.$api.mallApi.shopOnlineListApi(data).then(({ code, data }) => {
        if (code !== 200) return
        this.pager.count = data.total || 0
        this.tableData = data.list.map(item => {
          return {
            ...item,
            shopSwitch: item.shopStatus == 1
          }
        })
      })
    },
    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.busapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    }
  },
};
</script>

<style scoped lang='scss'>
.ticket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 30px 30px;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
  }

  .el-table {
    flex: 1;
    overflow: auto;
    margin-top: 20px;
  }
}

.d-paging {
  text-align: center;
}
.img {
    max-height: 120px;
  }
</style>