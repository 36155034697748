<template>
  <div class="add">
    <el-form ref="form" style="width: 80%" :inline="false" :model="form" label-width="120px">
      <el-form-item label="企业名称" v-if="id || userInfo && userInfo.type == 3">
        {{ form.companyName }}
      </el-form-item>
      <el-form-item label="企业名称" prop="shopIds" v-else>
        <el-cascader :options="shopOptions" v-model="form.shopIds" :props="{
      value: 'id',
      label: 'name',
      children: 'childList'
    }" ref="cascader" size="medium" filterable clearable style="width: 400px;"
>
        </el-cascader>
      </el-form-item>
      <el-form-item label="店铺名称" prop="name">
        <el-input v-model="form.name" size="medium" placeholder="请输入" maxlength="50" show-word-limit
          style="width: 400px;"></el-input>
      </el-form-item>
      <el-form-item label="店铺地址" prop="shopAddress">
        <el-input type="textarea" size="medium" :rows="4" maxlength="500" show-word-limit v-model="form.shopAddress"
          style="width: 400px;" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="一级业态" prop="businessCode">
        <el-select v-model="form.businessCode" placeholder="请选择" style="width: 400px;" size="medium">
          <el-option v-for="item in businessOptions" :key="item.businessCode" :label="item.businessName"
            :value="item.businessCode">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="二级业态" prop="secondBusinessCode">
        <el-select v-model="form.secondBusinessCode" placeholder="请选择" style="width: 400px;" size="medium">
          <el-option v-for="item in secondBusinessOptions" :key="item.businessCode" :label="item.businessName"
            :value="item.businessCode">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="店铺联系方式" prop="contactInfo">
        <el-input v-model="form.contactInfo" size="medium" placeholder="请输入" style="width: 400px;"></el-input>
      </el-form-item>
      <el-form-item label="店铺介绍" prop="shopDesc">
        <el-input type="textarea" size="medium" :rows="4" maxlength="500" show-word-limit v-model="form.shopDesc"
          style="width: 400px;" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="店铺LOGO" prop="shopLogo">
        <d-upload :file-list.sync="form.shopLogo" :limit="1" :multiple-preview="true"></d-upload>
      </el-form-item>
      <el-form-item label="行业资质" prop="qualification">
        <d-upload :file-list.sync="form.qualification" :limit="1" :multiple-preview="true"></d-upload>
      </el-form-item>
      <el-form-item label="店铺头图" prop="shopImage">
        <d-upload :file-list.sync="form.shopImage" :limit="1" :multiple-preview="true"></d-upload>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button type="info" size="large" @click="cancelFun">取消</el-button>
      <el-button type="primary" size="large" @click="submitFun">提交</el-button>
    </div>
  </div>
</template>

<script>
import DUpload from "@/components/d-upload";
export default {
  name: 'Add',
  components: { DUpload },
  data () {
    return {
      userInfo: null,
      id: null,
      form: {
        shopIds: [],
        name: '',
        shopDesc: '',
        shopImage: [],
        shopLogo: [],
        contactInfo: '',
        qualification: [],
        secondBusinessCode: '',
        shopAddress: '',
        businessCode: '',
        companyName: '',
      },
      shopOptions: [],
      businessOptions: [],
      secondBusinessOptions: [],
    }
  },
  watch: {

  },
  created () {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (this.userInfo.type == 3) {
      this.form.shopIds = [this.userInfo.shopId]
      this.form.companyName = this.userInfo.shopName
    }
    this.getBusinessList()
    this.secondBusinessList()
    this.getShopList()
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      let params = {
        id: this.id
      }
      this.$api.mallApi.shopOnlineApi(params).then(res => {
        if (res.code == 200) {
          this.form = {
            ...res.data,
            shopIds: [res.data.shopId],
            shopImage: [{ url: res.data.shopImage }],
            shopLogo: [{ url: res.data.shopLogo }],
            qualification: [{ url: res.data.qualification }],
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getBusinessList () {
      let params = {
      }
      this.$api.mallApi.businessListFirstApi(params).then(res => {
        if (res.code == 200) {
          this.businessOptions = res.data
        }
      })
    },
    secondBusinessList () {
      let params = {
      }
      this.$api.mallApi.businessListSecondApi(params).then(res => {
        if (res.code == 200) {
          this.secondBusinessOptions = res.data
        }
      })
    },
    submitFun () {
      const { shopIds, name, shopDesc, contactInfo, secondBusinessCode, shopAddress, businessCode, shopImage, shopLogo, qualification } = this.form
      let params = {
        id: this.id,
        shopId: shopIds && shopIds.length > 0 ? shopIds[shopIds.length - 1] : null,
        name,
        shopDesc,
        shopImage: shopImage.length ? shopImage.map(img => img.url).join(',') : '',
        shopLogo: shopLogo.length ? shopLogo.map(img => img.url).join(',') : '',
        contactInfo,
        qualification: qualification.length ? qualification.map(img => img.url).join(',') : '',
        secondBusinessCode,
        shopAddress,
        businessCode,
      }
      let api = this.id ? 'shopOnlineUpdateApi' : 'onlineSubmitApi'
      this.$api.mallApi[api](params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.cancelFun()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    cancelFun () {
      this.$router.back()
    },
    getShopList () {
      this.$api.busapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.shopOptions = child
          }
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.add {
  padding: 30px;
  background-color: #fff;
  overflow-y: auto;

  .footer {
    margin: 20px;
    text-align: right;
  }
}
</style>