<template>
  <div class="detail">
    <div style="text-align: right;">
      <el-button size="medium" type="primary" @click="back" v-if="$route.query.id">返回</el-button>
    </div>
    <div>
      <h3>订单信息</h3>
      <el-divider></el-divider>
      <div class="order-info">
        <div class="info-item">
          订单状态：
          <span :class="`status${detail.status}`">
            {{ $map.orderStatus[detail.status] || '' }}
         </span>
        </div>
        <div class="info-item">
          下单时间：{{ detail.createTime }}
        </div>
        <div class="info-item">
          订单编号：{{ detail.orderNo }}
        </div>
        <div class="info-item">
          类型：{{ $map.orderType[detail.type] || '' }}
        </div>
        <div class="info-item">
          用户姓名：{{ detail.createName }}
        </div>
        <div class="info-item">
          用户手机：{{ detail.createMobile }}
        </div>
        <div class="info-item">
          订单来源：{{ $map.orderSource[detail.orderSource] || '' }}
        </div>
        <div class="info-item">
          所属店铺：{{ detail.shopName }}
        </div>
        <div class="info-item">
          会员卡号：{{ detail.cardNo }}
        </div>
        <div class="info-item">
          取消原因：{{ detail.reason }}
        </div>
      </div>
    </div>
    <div>
      <h3>订单支付信息</h3>
      <el-divider></el-divider>
      <div class="order-info">
        <div class="info-item">
          支付单号：{{ orderInfo.orderNo }}
        </div>
        <div class="info-item">
          订单总价：{{ orderInfo.orderFee }}
        </div>
        <div class="info-item">
          优惠总价：{{ orderInfo.memberCouponFee }}
        </div>
        <template v-if="orderInfo.orderDetailList">
          <div class="info-item" v-for="(item, index) in orderInfo.orderDetailList" :key="index">
            {{ item.payCodeName }}：{{ item.payFee }}
          </div>
        </template>
        <div class="info-item">
          实付金额：{{ orderInfo.settleFee }}
        </div>
        <div class="info-item" v-if="orderInfo.orderDetailList">
          支付类型：{{ orderInfo.orderDetailList.map(item => item.payCodeName).join('|') }}
        </div>
        <div class="info-item">
          支付用户：{{ orderInfo.accountId }}
        </div>
        <div class="info-item">
          支付时间：{{ orderInfo.orderTime }}
        </div>

      </div>
    </div>
    <div>
      <h3>配送信息</h3>
      <el-divider></el-divider>
      <div class="order-info">
        <div class="info-item">
          配送方式：{{ $map.orderSendType[detail.sendType] || '' }}
        </div>
        <div class="info-item">
          收件人：{{ detail.receiveName }}
        </div>
        <div class="info-item">
          收件地址：{{ detail.receiveAddress }}
        </div>
        <div class="info-item">
          订单备注：{{ detail.remark }}
        </div>
        <div class="info-item">
          自提限时：{{ detail.pickUpLastDate }}
        </div>
        <div class="info-item">
          发货时间：{{ detail.sendTime }}
        </div>
        <div class="info-item">
          完成时间：{{ detail.finishTime }}
        </div>
        <div class="info-item">
          最后更新：{{ detail.updateTime }}
        </div>
        <div class="info-item">
          收货时间：{{ detail.receiveTime }}
        </div>
      </div>
    </div>
    <div>
      <h3>商品详情</h3>
      <el-divider></el-divider>
      <div class="bottom-btn" v-if="detail.status == 3">
        <el-button size="medium" type="primary" @click="openDialogTable" v-if="detail.sendType == 1">分包发货</el-button>
        <el-button size="medium" type="primary" @click="handleConfirm">确认发货</el-button>
        <el-button size="medium" type="primary" @click="openDialogForm" v-if="detail.sendType == 1">修改收货地址</el-button>
      </div>
      <div class="bottom-btn" v-if="detail.status == 4">
        <el-button size="medium" type="primary" @click="handleConfirmPick" v-if="detail.sendType == 2">确认自提</el-button>
      </div>
      <el-table :data="tableData" style="width: 100%" v-if="[3, 4, 5].includes(detail.status) && detail.sendType == 1" key="1">
        <el-table-column prop="goodsName" label="商品名称"></el-table-column>
        <el-table-column prop="status" label="发货状态">
          <template slot-scope="scope">{{ (detail.status == 4 || detail.status == 5) ? '已发货' : '未发货' }}</template>
        </el-table-column>
        <el-table-column prop="logisticsCompanyName" label="快递公司"></el-table-column>
        <el-table-column prop="logisticsNo" label="物流单号"></el-table-column>
        <el-table-column prop="sendTime" label="创建时间"></el-table-column>
        <el-table-column prop="price" label="单价"></el-table-column>
        <el-table-column prop="img" label="图片">
          <template slot-scope="scope" v-if="scope.row.covers">
            <img :src="scope.row.covers[0]" alt="" v-viewer="{ toolbar: false }" class="img">
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量"></el-table-column>
        <el-table-column prop="amount" label="总金额">
          <template slot-scope="scope">{{ scope.row.price * scope.row.count }}</template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="mini" type="text" style="color: #F56C6C" v-if="scope.row.id && detail.status == 3"
              @click="handleDelete(scope.row.id)">删除</el-button>
            <el-button size="mini" type="text" @click="checkSend(scope.row)" v-if="scope.row.id">查看物流</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table :data="detail.skus" style="width: 100%" v-else key="2">
        <el-table-column prop="name" label="商品名称"></el-table-column>
        <el-table-column prop="price" label="单价"></el-table-column>
        <el-table-column prop="img" label="图片">
          <template slot-scope="scope" v-if="scope.row.covers">
            <img :src="scope.row.covers[0]" alt="" v-viewer="{ toolbar: false }" class="img">
          </template>
        </el-table-column>
        <el-table-column prop="specification" label="规格"></el-table-column>
        <el-table-column prop="count" label="数量"></el-table-column>
        <el-table-column prop="amount" label="总金额">
          <template slot-scope="scope">{{ scope.row.price * scope.row.count }}</template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="快递发货" :visible.sync="dialogTableVisible" width="1200px">
      <el-form :model="sendForm">
        <el-form-item label="物流公司" required>
          <el-select v-model="sendForm.logisticsCompanyId" placeholder="请选择" style="width: 220px;" size="medium">
            <el-option :label="item.name" :value="item.id" v-for="(item, index) in companyList" :key="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="物流单号" required>
          <el-input v-model="sendForm.logisticsNo" style="width: 220px;" size="medium"></el-input>
        </el-form-item>
      </el-form>
      <el-table :data="gridData" style="width: 100%" @selection-change="handleSelectionChange" key="3">
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称">
        </el-table-column>
        <el-table-column prop="img" label="图片">
          <template slot-scope="scope" v-if="scope.row.covers">
            <img :src="scope.row.covers[0]" alt="" v-viewer="{ toolbar: false }" class="img">
          </template>
        </el-table-column>
        <el-table-column prop="specification" label="规格"></el-table-column>
        <el-table-column prop="maxCount" label="可发货数量"></el-table-column>
        <el-table-column prop="count" label="数量">
          <template slot-scope="scope">
            <el-input v-model="scope.row.count" size="medium"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价"></el-table-column>
        <el-table-column prop="amount" label="总金额">
          <template slot-scope="scope">{{ scope.row.price * scope.row.count }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSend">取 消</el-button>
        <el-button type="primary" @click="confirmSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改收件信息" :visible.sync="dialogFormVisible" width="450px">
      <el-form :model="receiveForm" label-width="80px">
        <el-form-item label="收件人" required>
          <el-input v-model="receiveForm.receiveName" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" required>
          <el-input v-model="receiveForm.receiveMobile" size="medium"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" required>
          <el-cascader style="width: 100%" size="medium" :options="areaOptions" v-model="receiveForm.receiveArea">
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" required>
          <el-input v-model="receiveForm.receiveAddress" size="medium"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelReceive">取 消</el-button>
        <el-button type="primary" @click="confirmReceive">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="物流信息" :visible.sync="dialogLogisticsVisible" width="450px">
      <div class="logistics-title">
          <div>{{ logisticsInfo.name }}</div>
          <div>{{ logisticsInfo.number }}</div>
        </div>
      <div style="padding:20px" >
        <el-timeline>
          <el-timeline-item v-for="(node, index) in logisticsInfo.list" :key="index" 
             :timestamp="node.time" type="primary">
            <div> {{ node.status }}</div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data'
export default {
  props: ['orderId'],
  data () {
    return {
      logisticsInfo: {
        list: []
      },
      dialogLogisticsVisible: false,
      id: '',
      areaOptions: regionData,
      receiveForm: {
        receiveAddress: '',
        receiveArea: [],
        receiveMobile: '',
        receiveName: '',
      },
      dialogFormVisible: false,
      orderSkus: [],
      companyList: [],
      sendForm: {
        logisticsCompanyId: '',
        logisticsNo: '',
      },
      gridData: [],
      dialogTableVisible: false,
      tableData: [],
      detail: {},
      orderInfo: {}
    }
  },
  computed: {

  },
  components: {
  },
  created () {
    this.id = this.$route.query.id || this.orderId
    this.init()
  },
  mounted () {
  },
  methods: {
    checkSend(row) {
      let params = {
        goodsOrderId: row.goodsOrderId,
        logisticsNo: row.logisticsNo,
      }
      this.$api.mallApi.goodsOrderSkuSendApi(params).then(res => {
        if (res.code == 200 && res.data.data) {
          this.logisticsInfo = res.data.data
          this.dialogLogisticsVisible = true
        } else {
          this.$message.error(res.data.desc)
        }
      })
    },
    handleConfirmPick () {
      this.$confirm('是否确认此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: this.detail.id
          }
          this.$api.mallApi.goodsOrderPickUpConfirmApi(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.init()
            }
          })
        })
        .catch(() => {
        })
    },
    handleConfirm () {
      this.$confirm('是否确认此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: this.detail.id
          }
          this.$api.mallApi.goodsOrderSendConfirmApi(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.init()
            }
          })
        })
        .catch(() => {
        })

    },
    handleDelete (id) {
      this.$confirm('是否确认删除该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id
          }
          this.$api.mallApi.goodsOrderDeleteApi(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.getOrderSendList()
            }
          })
        })
        .catch(() => {
        })
    },
    cancelReceive () {
      this.dialogFormVisible = false
    },
    confirmReceive () {
      let params = {
        id: this.detail.id,
        receiveAddress: this.receiveForm.receiveAddress,
        receiveArea: this.getReceiveArea(this.receiveForm.receiveArea || []),
        receiveMobile: this.receiveForm.receiveMobile,
        receiveName: this.receiveForm.receiveName,
      }
      this.$api.mallApi.goodsOrderReceiveApi(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.init()
          this.dialogFormVisible = false
        }
      })
    },
    getReceiveArea (data) {
      const result = [];
      let currentParent = null;
      data.forEach(item => {
        const newItem = { label: CodeToText[item], value: item, children: [] };
        if (currentParent) {
          currentParent.children.push(newItem);
        } else {
          result.push(newItem);
        }
        currentParent = newItem;
      });

      return result;
    },
    flatArea (data) {
      const result = [];
      function flattenHelper (node) {
        result.push(node.value);
        if (node.children) {
          node.children.forEach(child => {
            flattenHelper(child);
          });
        }
      }
      data.forEach(item => {
        flattenHelper(item);
      });
      return result;
    },

    openDialogForm () {
      this.receiveForm = {
        receiveAddress: this.detail.receiveAddress,
        receiveArea: this.flatArea(this.detail.receiveArea || []),
        receiveMobile: this.detail.receiveMobile,
        receiveName: this.detail.receiveName,
      }
      this.dialogFormVisible = true
    },
    cancelSend () {
      this.dialogTableVisible = false
    },
    confirmSend () {
      if (!this.sendForm.logisticsCompanyId) {
        this.$message.error('物流公司不能为空')
        return
      }
      if (!this.sendForm.logisticsNo) {
        this.$message.error('物流单号不能为空')
        return
      }
      if (!this.orderSkus.length) {
        this.$message.error('请选择要发货商品')
        return
      }
      if (this.orderSkus.find(item => (isNaN(item.count) || Number(item.count) <= 0))) {
        this.$message.error('发货数量需为正整数')
        return
      }
      if (this.orderSkus.find(item =>  Number(item.count) > Number(item.maxCount))) {
        this.$message.error('发货数量不能大于可发货数量')
        return
      }
      let params = {
        goodsOrderId: this.detail.id,
        logisticsCompanyId: this.sendForm.logisticsCompanyId,
        logisticsNo: this.sendForm.logisticsNo,
        orderSkus: this.orderSkus.map(item => {
          return {
            count: item.count,
            id: item.id,
          }
        })
      }
      this.$api.mallApi.goodsOrderSendApi(params).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getOrderSendList()
          this.dialogTableVisible = false
        }
      })
    },
    handleSelectionChange (val) {
      this.orderSkus = val;
    },
    openDialogTable () {
      this.sendForm = {
        logisticsCompanyId: '',
        logisticsNo: '',
      }
      this.orderSkus = []
      this.getSendSkuList()
      this.dialogTableVisible = true
    },
    async getSendSkuList () {
      let params = {
        goodsOrderId: this.detail.id
      }
      await this.$api.mallApi.goodsOrderSendSkuListApi(params).then((res) => {
        if (res.code !== 200) return
        this.gridData = res.data.map(item => {
          return {
            ...item,
            maxCount: item.count
          }
        })
      })
    },
    async init () {
      await this.getDetail()
      if ([3, 4, 5].includes(this.detail.status) && this.detail.sendType == 1) {
        await this.getOrderSendList()
        await this.getLogisticsCompanyList()
      }
    },
    back () {
      this.$router.go(-1)
    },
    async getLogisticsCompanyList () {
      let params = {
        pageNum: 1,
        pageSize: -1,
        shopId: this.detail.shopId
      }
      await this.$api.mallApi.logisticsCompanyListApi(params).then((res) => {
        if (res.code !== 200) return
        this.companyList = res.data.list
      })
    },
    async getOrderSendList () {
      let params = {
        goodsOrderId: this.detail.id
      }
      await this.$api.mallApi.goodsOrderSendListApi(params).then((res) => {
        if (res.code !== 200) return
        this.tableData = res.data
      })
    },
    async getDetail () {
      let params = {
        id: this.id
      }
      await this.$api.mallApi.goodsOrderApi(params).then((res) => {
        if (res.code !== 200) return
        this.detail = res.data
        let orderInfo = res.data.orderInfo ? res.data.orderInfo.orderInfo.orderInfoList.find(item => item.shopCode === res.data.shopCode) : {}
        this.orderInfo = {
          ...orderInfo,
          accountId: res.data.orderInfo ? res.data.orderInfo.orderInfo.accountId : ''
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang='scss'>
.detail {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.order-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}

.info-item {
  flex-basis: 33.3%;
  font-size: 15px;
  margin-bottom: 20px;
}

.bottom-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}
.logistics-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px
}

.img {
  max-height: 120px;
}
.status1,.status3,.status4,.status6,.status8 {
    color: #8080FF
  }
  .status2,.status7,.status9,.status10 {
    color: #D9001B
  }
  .status5 {
    color: #45E842;
  }
</style>