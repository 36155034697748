<template>
  <div class="add">
    <div class="shop-info">
      <el-form ref="form" :inline="false" :model="form" label-width="120px" style="flex: 1;">
        <el-form-item label="企业名称">
          {{ form.companyName }}
        </el-form-item>
        <el-form-item label="店铺名称" prop="name">
          {{ form.name }}
        </el-form-item>
        <el-form-item label="店铺地址" prop="shopAddress">
          <el-input type="textarea" size="medium" :rows="4" maxlength="500" show-word-limit v-model="form.shopAddress"
            style="width: 400px;" placeholder="请输入" disabled></el-input>
        </el-form-item>
        <el-form-item label="一级业态" prop="businessCode">
          <el-select v-model="form.businessCode" placeholder="请选择" style="width: 400px;" size="medium" disabled>
            <el-option v-for="item in businessOptions" :key="item.businessCode" :label="item.businessName"
              :value="item.businessCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="二级业态" prop="secondBusinessCode">
          <el-select v-model="form.secondBusinessCode" placeholder="请选择" style="width: 400px;" size="medium" disabled>
            <el-option v-for="item in secondBusinessOptions" :key="item.businessCode" :label="item.businessName"
              :value="item.businessCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="店铺联系方式" prop="contactInfo">
          {{ form.contactInfo }}
        </el-form-item>
        <el-form-item label="店铺介绍" prop="shopDesc">
          <el-input type="textarea" size="medium" :rows="4" maxlength="500" show-word-limit v-model="form.shopDesc"
            disabled style="width: 400px;" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="店铺LOGO" prop="shopLogo">
          <img :src="form.shopLogo" alt="" class="img" v-viewer="{ toolbar: false }">
        </el-form-item>
        <el-form-item label="行业资质" prop="qualification">
          <img :src="form.qualification" alt="" class="img" v-viewer="{ toolbar: false }">
        </el-form-item>
        <el-form-item label="店铺头图" prop="shopImage">
          <img :src="form.shopImage" alt="" class="img" v-viewer="{ toolbar: false }">
        </el-form-item>
      </el-form>
      <div class="approval-info">
        <div class="approval-item">
          <span class="approval-label">状态：</span>
          <span v-if="form.approvalStatus == 1" class="blue">待审核</span>
          <span v-if="form.approvalStatus == 2" style="color: #F56C6C">驳回</span>
          <span v-if="form.approvalStatus == 3" style="color: #04F21C">通过</span>
        </div>
        <div class="approval-item" v-if="form.approvalStatus == 1">
          <span class="approval-label">提交时间：</span>
          <span>{{ form.approvalSubmitTime }}</span>
        </div>
        <div class="approval-item" v-if="form.approvalStatus != 1">
          <span class="approval-label">审核人：</span>
          <span>{{ form.approvalUserName }}</span>
        </div>
        <div class="approval-item" v-if="form.approvalStatus == 2">
          <span class="approval-label">驳回理由：</span>
          <span>{{ form.reason }}</span>
          <div v-if="form.refuseResource" style="margin-top: 10px;">
            <img :src="item" alt="" class="img" v-for="(item, index) in form.refuseResource.split(',')" v-viewer="{ toolbar: false }">
          </div>
        </div>
        <div class="approval-item" v-if="form.approvalStatus != 1">
          <span class="approval-label">审核时间：</span>
          <span>{{ form.updateTime }}</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <el-button type="info" size="large" @click="cancelFun">返回</el-button>
    </div>
  </div>
</template>

<script>
import DUpload from "@/components/d-upload";
export default {
  name: 'Add',
  components: { DUpload },
  data () {
    return {
      id: null,
      form: {},
      businessOptions: [],
      secondBusinessOptions: [],
    }
  },
  watch: {

  },
  async created () {
    await this.getBusinessList()
    await this.secondBusinessList()
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      let params = {
        id: this.id
      }
      this.$api.mallApi.shopOnlineApi(params).then(res => {
        if (res.code == 200) {
          this.form = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    async getBusinessList () {
      let params = {
      }
      await this.$api.mallApi.businessListFirstApi(params).then(res => {
        if (res.code == 200) {
          this.businessOptions = res.data
        }
      })
    },
    async secondBusinessList () {
      let params = {
      }
      await this.$api.mallApi.businessListSecondApi(params).then(res => {
        if (res.code == 200) {
          this.secondBusinessOptions = res.data
        }
      })
    },
    cancelFun () {
      this.$router.back()
    },
  }
}
</script>

<style lang="scss" scoped>
.add {
  padding: 30px;
  background-color: #fff;
  overflow-y: auto;

  .footer {
    margin: 20px;
    text-align: right;
  }
}

.img {
  max-height: 120px;
}

.shop-info {
  display: flex;
}

.approval-info {
  flex: 1;
}

.approval-item {
  margin-bottom: 20px;
}
</style>