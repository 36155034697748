<template>
  <div class="detail">
    <div style="padding: 20px;">
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="订单号">
          <el-input v-model="form.orderNo" placeholder="订单号" size="medium"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="orderInfo">
      <GoodsReturnDetail :orderId="orderInfo.id" v-if="[2].includes(orderInfo.type)"></GoodsReturnDetail>
      <GoodsDetail :orderId="orderInfo.id" v-else></GoodsDetail>
    </div>
  </div>
</template>

<script>
import GoodsDetail from "./goodsDetail.vue"
import GoodsReturnDetail from "./goodsReturnDetail.vue"

export default {
  data () {
    return {
      orderInfo: null,
      form: {
        orderNo: ''
      }
    }
  },
  computed: {

  },
  components: {
    GoodsDetail,
    GoodsReturnDetail
  },
  created () {
  },
  mounted () {
  },
  methods: {
    onSubmit() {
      let params = {
        orderNo: this.form.orderNo
      }
      this.$api.mallApi.goodsOrderByOrdernoApi(params).then(res => {
        if (res.code == 200) {
          this.orderInfo = res.data
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang='scss'>
.detail {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  overflow: auto;
}

.order-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}

.info-item {
  flex-basis: 33.3%;
  font-size: 15px;
  margin-bottom: 20px;
}

.bottom-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}
</style>