<template>
  <div class="reportMarket" v-loading="loading">
    <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent" />
    <div class="action_btn">
      <el-button type="primary" size="medium" @click="batchFun(3)">通过</el-button>
      <el-button type="danger" size="medium" @click="batchFun(2)">驳回</el-button>
    </div>
    <d-lable :tableData='tableData' :columnData='columnData' @selection-change="handleSelectionChange">
      <template v-slot:approvalStatus="{ row }">
        <span v-if="row.approvalStatus == 1" class="blue">待审核</span>
        <span v-if="row.approvalStatus == 2" style="color: #F56C6C;">驳回</span>
        <span v-if="row.approvalStatus == 3" style="color: #04F21C">通过</span>
      </template>
      <template v-slot:shopLogo="{ row }">
        <img :src="row.shopLogo" alt="" class="img" v-viewer="{ toolbar: false }">
      </template>
      <template v-slot:action="{ row }">
        <span class="mgr10 blue" @click="handleDetailClick(row)">详情</span>
      </template>
    </d-lable>
    <d-paging :pager="pager" @change="getItemList" />
    <el-dialog title="提示" :visible.sync="show" width="600px">
      <el-form :model="form" ref="form" size="medium" label-width="110px">
        <el-form-item label="驳回理由" prop="reason">
          <el-input type="textarea" v-model="form.reason">
          </el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="refuseResource">
          <d-upload :file-list.sync="form.refuseResource" :limit="5" :multiple-preview="true"></d-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" size="medium" @click="handleCancel">取消</el-button>
        <el-button type="primary" size="medium" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import DUpload from "@/components/d-upload";
import DSearch from '@/components/d-search'
import DLable from '@/components/d-table'
import DPaging from '@/components/d-paging'
export default {
  data () {
    return {
      form: {
        reason: '',
        refuseResource: [],
      },
      show: false,
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      loading: false,
      searchData: {
        searchItem: [{
          label: '企业名称',
          value: 'companyName',
          type: 'commonInput',
          placeholder: '请输入企业名称'
        }, {
          label: '选择店铺',
          value: 'shopIds',
          type: 'linkageSelect',
          placeholder: '',
          options: [],
          filterable: true,
          optionProps: {
            value: 'id',
            label: 'name',
            children: 'childList'
          }
        }],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {}
      },
      columnData: [
        { prop: 'selection', type: 'selection', label: '' },
        { prop: 'companyName', label: '企业名称' },
        { prop: 'name', label: '店铺名称' },
        { prop: 'shopLogo', label: '店铺LOGO', slotProp: true, width: 120 },
        { prop: 'contactInfo', label: '联系电话', },
        { prop: 'approvalSubmitTime', label: '创建时间' },
        { prop: 'updateTime', label: '更新时间' },
        { prop: 'approvalStatus', label: '审批状态', slotProp: true },
        { prop: 'action', label: '操作', slotProp: true },
      ],
      tableData: [],
      ids: []
    }
  },
  computed: {
  },
  components: {
    DSearch,
    DLable,
    DPaging,
    DUpload
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    handleDetailClick (row) {
      this.$router.push({ path: '/mallModule/shop/online/detail', query: { id: row.id } })
    },
    handelConfirm () {
      if (!this.form.reason) {
        return this.$message.error('请输入驳回理由')
      }
      let params = this.ids.map(item => {
        return {
          id: item,
          reason: this.form.reason,
          refuseResource: this.form.refuseResource.length ? this.form.refuseResource.map(img => img.url).join(',') : '',
        }
      })
      this.$api.mallApi.onlineRefuseBatchApi(params).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.show = false
          this.getItemList()
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    handleCancel () {
      this.show = false
    },
    init () {
      this.getShopList()
      this.getItemList()
    },

    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.busapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    },
    handleSelectionChange (selects) {
      this.ids = selects.map(e => e.id)
    },
    batchFun (n) {
      const ids = this.ids
      if (!ids || !ids.length) {
        return this.$message.warning('请选择需要操作的数据')
      }
      if (n === 3) {
        let params = this.ids.map(item => {
          return {
            id: item,
          }
        })
        this.$api.mallApi.onlinePassBatch(params).then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getItemList()
          } else {
            this.$message.error(res.msg);
          }
        })
      } else {
        this.form = {
          reason: '',
          refuseResource: [],
        }
        this.show = true
        return
      }
    },
    /*,*
     * 搜索按钮点击
     **/
    searchEvent () {
      this.getItemList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent () {
      this.pager.pageNum = 1;
      this.pager.pageSize = 10;
      this.searchData.params.companyName = '';
      this.searchData.params.shopIds = [];
      this.getItemList()
    },

    // 分页列表
    getItemList () {
      this.ids = []
      const { shopIds, companyName } = this.searchData.params
      const params = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        companyName,
        shopId: shopIds && shopIds.length > 0 ? shopIds[shopIds.length - 1] : null,
      }
      this.$api.mallApi.shopOnlineListApi(params)
        .then((res) => {
          if (res.code === 200) {
            this.pager.count = res.data.total
            this.tableData = res.data.list
          }
        })
    }

  },

}
</script>
<style lang="scss" scoped>
.reportMarket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;

  .el-table {
    flex: 1;
    margin-top: 10px;
    padding: 0 20px;
    overflow-y: auto;
  }

  .d-paging {
    margin-top: 50px;
    text-align: center;
  }

  .action_btn {
    margin-top: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: right;
  }
  .img {
    max-height: 120px;
  }
}
</style>
