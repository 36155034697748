<template>
  <div class="ticket" v-if="$route.path === '/mallModule/goods/send'">
    <d-search :searchData="searchData" @searchEvent="searchEvent" @resetEvent="resetEvent" />
    <div class="content">
      <div class="header">
        <div class="operation">
          <el-button size="medium" type="primary" @click="exportEvent">导出</el-button>
        </div>
      </div>
      <d-lable height="calc(100vh - 420px)" :tableData='tableData' :columnData='columnData' @viewEvent="viewEvent">
        <template v-slot:orderSource="{ row }">
          {{ $map.orderSource[row.orderSource] || '' }}
        </template>
        <template v-slot:status="{ row }">
          <span :class="`status${row.status}`">
          {{ $map.orderStatus[row.status] || '' }}
         </span>
        </template>
        <template v-slot:isReturn="{ row }">
          <span v-if="row.isReturn">退货</span>
        </template>
      </d-lable>
    </div>
    <d-paging :pager="pager" @change="getItemList" />
  </div>
  <router-view v-else />
</template>

<script>
import DSearch from "@/components/d-search";
import DLable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import { onDownload } from "@/utils/utils";
export default {
  components: {
    DSearch,
    DLable,
    DPaging,
  },
  data () {
    return {
      userInfo: null,
      searchData: {
        searchItem: [
          {
            label: '订单号',
            value: 'orderNo',
            type: 'commonInput',
            placeholder: '请输入'
          },
          {
            label: "订单来源",
            value: "orderSource",
            type: "commonSelect",
            placeholder: "",
            options: [
              {
                label: '企业商城',
                value: 1,

              },
              {
                label: '会员商城',
                value: 2
              }
            ],
          },
          {
            label: '手机号',
            value: 'createMobile',
            type: 'commonInput',
            placeholder: '请输入'
          },
          {
            label: '会员卡号',
            value: 'cardNo',
            type: 'commonInput',
            placeholder: '请输入'
          },
          {
            label: "下单时间起",
            value: "createTimeBegin",
            type: "datetime",
            options: {

            }
          },
          {
            label: "下单时间止",
            value: "createTimeEnd",
            type: "datetime",
            options: {

            }
          },
          {
            label: "完成时间起",
            value: "finishTimeBegin",
            type: "datetime",
            options: {

            }
          },
          {
            label: "完成时间止",
            value: "finishTimeEnd",
            type: "datetime",
            options: {

            }
          },
          {
            label: '最小订单金额',
            value: 'orderAmountBegin',
            type: 'commonInput',
            placeholder: '请输入'
          },
          {
            label: '最大订单金额',
            value: 'orderAmountEnd',
            type: 'commonInput',
            placeholder: '请输入'
          },
          {
            label: "订单状态",
            value: "statusList",
            type: "commonSelect",
            placeholder: "",
            options: [
              {
                label: '待收货',
                value: 4
              },
              {
                label: '已完成',
                value: 5
              },
            ],
          },

        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
        },
        params: {
          shopIds: [],
          orderNo: '',
          orderSource: '',
          createMobile: '',
          cardNo: '',
          createTimeBegin: '',
          createTimeEnd: '',
          finishTimeBegin: '',
          finishTimeEnd: '',
          orderAmountBegin: '',
          orderAmountEnd: '',
          statusList: '',
        },
      },
      columnData: [
        { prop: 'id', label: '订单ID' },
        { prop: 'orderNo', label: '订单号' },
        { prop: 'createName', label: '顾客姓名' },
        { prop: 'createMobile', label: '手机号' },
        { prop: 'cardNo', label: '会员卡号' },
        { prop: 'orderAmount', label: '订单金额' },
        { prop: 'payAmount', label: '实付金额' },
        { prop: 'payMethod', label: '支付渠道', },
        { prop: 'orderSource', label: '订单来源', slotProp: true, },
        { prop: 'shopName', label: '所属店铺' },
        { prop: 'createTime', label: '订单时间' },
        { prop: 'pickUpLastDate', label: '自提限时' },
        { prop: 'sendTime', label: '发货时间' },
        { prop: 'finishTime', label: '完成时间' },
        { prop: 'updateTime', label: '更新时间' },
        { prop: 'status', label: '状态', slotProp: true, },
        { prop: 'isReturn', label: '退货标识', slotProp: true, },
        {
          prop: 'operate',
          label: '操作',
          fixed: 'right',
          type: {
            viewBtn: true,
          }
        }
      ],
      tableData: [],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
    };
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/mallModule/goods/send') {
        this.getItemList()
      }
    }
  },
  created () {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (this.userInfo.type != 3) {
      this.searchData.searchItem.unshift(
        {
          label: '所属店铺',
          value: 'shopIds',
          type: 'linkageSelect',
          placeholder: '',
          options: [],
          filterable: true,
          optionProps: {
            value: 'id',
            label: 'name',
            children: 'childList'
          }
        },
      )
      this.getShopList()
    }
    this.getItemList()
  },
  methods: {
    viewEvent (column) {
      this.$router.push({
        path: "/mallModule/goods/detail",
        query: { id: column.id }
      });
    },
    searchEvent () {
      this.pager.page = 1
      this.getItemList()
    },

    resetEvent () {
      this.pager.page = 1;
      this.pager.rows = 10;
      this.pager.count = 0;
      this.searchData.params.shopIds = []
      this.searchData.params.orderNo = ''
      this.searchData.params.orderSource = ''
      this.searchData.params.createMobile = ''
      this.searchData.params.cardNo = ''
      this.searchData.params.createTimeBegin = ''
      this.searchData.params.createTimeEnd = ''
      this.searchData.params.finishTimeBegin = ''
      this.searchData.params.finishTimeEnd = ''
      this.searchData.params.orderAmountBegin = ''
      this.searchData.params.orderAmountEnd = ''
      this.searchData.params.statusList = ''
      this.getItemList()
    },

    setParams () {
      const { shopIds, statusList } = this.searchData.params
      let params = {
        ...this.searchData.params
      }
      if (shopIds && shopIds.length > 0) {
        params.shopId = shopIds[shopIds.length - 1] || null
      }
      delete params.shopIds
      return {
        ...params,
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        statusList: statusList || '4,5',
        sendType: 2
      }
    },
    getItemList () {
      const data = this.setParams()
      this.$api.mallApi.goodsOrderListApi(data).then((res) => {
        if (res.code !== 200) return
        this.pager.count = res.data.total || 0
        this.tableData = res.data.list || []
      })
    },
    // 导出
    exportEvent () {
      let params = this.setParams()
      this.$api.mallApi.goodsOrderExportApi(params).then(({ headers, data }) => {
        onDownload(headers, data)
      })
    },
    /**
 * 获取选择店铺列表
 */
    getShopList () {
      this.$api.busapi.getShopList()
        .then(({ data, code }) => {
          if (code == 200) {
            this.childList = data.childList
            const child = [{
              id: data.id,
              name: data.name,
              childList: data.childList
            }]
            this.searchData.searchItem.forEach(ele => {
              if (ele.type === 'linkageSelect') {
                ele.options = child
              }
            })
          }
        })
    }

  },
};
</script>

<style scoped lang='scss'>
.ticket {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  height: 100%;
}

.content {
  flex: 1;
  padding: 0 20px;
  box-sizing: border-box;

  .header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}

.d-paging {
  text-align: center;
}

.status1,.status3,.status4,.status6,.status8 {
    color: #8080FF
  }
  .status2,.status7,.status9,.status10 {
    color: #D9001B
  }
  .status5 {
    color: #45E842;
  }
</style>