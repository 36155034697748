<template>
  <div class="detail">
    <div style="display: flex; justify-content: space-between;">
      <div style="padding:20px" >
        <el-timeline>
          <el-timeline-item v-for="(node, index) in nodeDetails" :key="index" 
             :timestamp="node.dealTime"  :type="node.type">
            <div> {{ node.name }}</div>
            <div> {{ node.dealReason }}</div>
            <div> {{ node.dealRemark }}</div>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div>
        <el-button size="medium" type="primary" @click="back" v-if="$route.query.id" >返回</el-button>
      </div>
    </div>
    <div>
      <h3>退货理由</h3>
      <el-divider></el-divider>
      <div class="order-info">
        <div class="info-item">
          订单状态：
          <span :class="`status${detail.status}`">
            {{ $map.orderStatus[detail.status] || '' }}
         </span>
        </div>
        <div class="info-item">
          申请时间：{{ detail.createTime }}
        </div>
        <div class="info-item">
          所属店铺：{{ detail.shopName }}
        </div>
        <div class="info-item">
          申请原因：{{ detail.reason }}
        </div>
      </div>
      <div style="padding: 0 20px 20px 20px;">
        问题描述：{{ detail.description }}
        <div v-if="detail.descriptionUrls" style="margin-top: 10px;" class="img-list">
          <img :src="item" alt="" class="img" v-for="(item, index) in detail.descriptionUrls" v-viewer="{ toolbar: false }">
        </div>
      </div>
      <div style="padding: 0 20px 20px 20px;">
        拒绝理由：{{ detail.refuseReason }}
        <div v-if="detail.refuseUrls" style="margin-top: 10px;" class="img-list">
          <img :src="item" alt="" class="img" v-for="(item, index) in detail.refuseUrls" v-viewer="{ toolbar: false }">
        </div>
      </div>
    </div>
    <div>
      <h3>订单信息</h3>
      <el-divider></el-divider>
      <div class="order-info">
        <div class="info-item">
          退货单号：{{ detail.orderNo }}
        </div>
        <div class="info-item">
          原订单编号：{{ detail.originalOrderNo }}
        </div>
        <div class="info-item">
          订单来源：{{ $map.orderSource[detail.orderSource] || '' }}
        </div>
        <div class="info-item">
          顾客姓名：{{ detail.createName }}
        </div>
        <div class="info-item">
          顾客手机：{{ detail.createMobile }}
        </div>
        <div class="info-item">
          会员卡号：{{ detail.cardNo }}
        </div>
      </div>
    </div>
    <div>
      <h3>退款明细</h3>
      <el-divider></el-divider>
      <div class="order-info">
        <div class="info-item">
          退款金额：{{ orderInfo.orderFee }}
        </div>
        <template v-if="orderInfo.orderDetailList">
          <div class="info-item" v-for="(item, index) in orderInfo.orderDetailList" :key="index">
            {{ item.payCodeName }}：{{ item.payFee }}
          </div>
        </template>
        <div class="info-item">
          已退邮费：{{ orderInfo.extraFee }}
        </div>
      </div>
    </div>
    <div>
      <h3>商品明细</h3>
      <el-divider></el-divider>
      <el-table :data="detail.skus" style="width: 100%">
        <el-table-column prop="name" label="商品名称"></el-table-column>
        <el-table-column prop="price" label="单价"></el-table-column>
        <el-table-column prop="img" label="图片">
          <template slot-scope="scope" v-if="scope.row.covers">
            <img :src="scope.row.covers[0]" alt="" v-viewer="{ toolbar: false }" class="img">
          </template>
        </el-table-column>
        <el-table-column prop="specification" label="规格"></el-table-column>
        <el-table-column prop="count" label="数量"></el-table-column>
        <el-table-column prop="amount" label="总金额">
          <template slot-scope="scope">{{ scope.row.price * scope.row.count }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom-btn" v-if="detail.status == 6">
      <el-button size="medium" type="primary" @click="handlePass">通过</el-button>
      <el-button size="medium" type="danger" @click="handleRefuse">驳回</el-button>
    </div>
    <div class="bottom-btn" v-if="detail.status == 8">
      <el-button size="medium" type="primary" @click="confirmPass" v-if="detail.payStatus == 0">确认收货</el-button>
      <el-button size="medium" type="danger" @click="confirmRefuse">拒绝收货</el-button>
    </div>
    <el-dialog title="提示" :visible.sync="show" width="600px">
      <el-form :model="form" ref="form" size="medium" label-width="110px">
        <el-form-item label="驳回理由" prop="refuseReason">
          <el-input type="textarea" v-model="form.refuseReason">
          </el-input>
        </el-form-item>
        <el-form-item label="上传图片" prop="refuseUrls">
          <d-upload :file-list.sync="form.refuseUrls" :limit="5" :multiple-preview="true"></d-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" size="medium" @click="handleCancel">取消</el-button>
        <el-button type="primary" size="medium" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import DUpload from "@/components/d-upload";
export default {
  props: ['orderId'],
  data () {
    return {
      status: '',
      nodeDetails: [],
      show: false,
      form: {
        refuseReason: '',
        refuseUrls: [],
      },
      tableData: [],
      detail: {},
      orderInfo: {}
    }
  },
  computed: {

  },
  components: {
    DUpload
  },
  created () {
    this.id = this.$route.query.id || this.orderId
    this.init()
  },
  mounted () {
  },
  methods: {
    confirmPass () {
      this.$confirm('是否确认此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: this.detail.id
          }
          this.$api.mallApi.confirmPassApi(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.init()
            }
          })
        })
        .catch(() => {
        })
    },
    confirmRefuse () {
      this.form = {
        refuseReason: '',
        refuseUrls: [],
      }
      this.status = 8
      this.show = true
    },
    handlePass () {
      this.$confirm('是否确认此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: this.detail.id
          }
          this.$api.mallApi.goodsOrderReturnPassApi(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg)
              this.init()
            }
          })
        })
        .catch(() => {
        })
    },
    handleRefuse () {
      this.form = {
        refuseReason: '',
        refuseUrls: [],
      }
      this.status = 6
      this.show = true
    },
    handelConfirm () {
      let params = {
        id: this.detail.id,
        refuseReason: this.form.refuseReason,
        refuseUrls: this.form.refuseUrls.map(item => item.url),
      }
      let api = this.status == 6 ? 'goodsOrderReturnRefuseApi' : 'confirmRefuseApi'
      this.$api.mallApi[api](params).then((res) => {
        if (res.code === 200) {
          this.$message.success(res.msg);
          this.show = false
          this.init()
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    handleCancel () {
      this.show = false
    },
    async init () {
      await this.getDetail()
    },
    back () {
      this.$router.go(-1)
    },

    async getDetail () {
      let params = {
        id: this.id
      }
      await this.$api.mallApi.goodsOrderApi(params).then((res) => {
        if (res.code !== 200) return
        this.detail = res.data
        let orderInfo = res.data.orderInfo ? res.data.orderInfo.orderInfo.orderInfoList.find(item => item.shopCode === res.data.shopCode) : {}
        this.orderInfo = {
          ...orderInfo,
        }
        this.nodeDetails = (res.data.nodeDetails || []).map(item => {
          return {
            ...item,
            type: item.dealType === 0 ? 'primary' : item.dealType === 1 ? 'danger ' : 'info'
          }
        })
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang='scss'>
.detail {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

.order-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
}

.info-item {
  flex-basis: 33.3%;
  font-size: 15px;
  margin-bottom: 20px;
}

.bottom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.img-list {
  display: flex;
  align-items: center;
}

.img {
  margin: 10px;
  max-height: 120px;
}
.status1,.status3,.status4,.status6,.status8 {
    color: #8080FF
  }
  .status2,.status7,.status9,.status10 {
    color: #D9001B
  }
  .status5 {
    color: #45E842;
  }
</style>